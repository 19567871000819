import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { EntityEditForm, FormCheckField, FormInputField } from '../../components';
import APIClient from '../../services/APIClient';
import { WithRouterProps } from '../withRouter';
import { Unit } from '../../types/model';

function UnitEdit({ toastManager }: WithRouterProps) {
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState<string>('');
  const [erpCode, setErpCode] = useState<string>();
  const [enabled, setEnabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdding] = useState(typeof id === 'undefined');

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdding) {
      onRetrieveEntity();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  function handleErrorMessage(message: string, error: unknown) {
    console.error(message, error);

    toastManager.add(`Ocurrió un error: "${error}"`, {
      appearance: 'error',
    });
  }

  async function onRetrieveEntity() {
    try {
      const unitRes = await APIClient.get(`/units/${id}`);
      const entity = unitRes.data.data as Unit;
      setName(entity.name);
      setErpCode(entity.erpCode);
      setEnabled(entity.enabled);
    } catch (error) {
      handleErrorMessage('Error in on retrieve Unit', error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSaveEntity(entityToSave: Unit) {
    try {
      let saveResponse: AxiosResponse;

      if (isAdding) {
        saveResponse = await APIClient.post('/units', entityToSave);
      } else {
        saveResponse = await APIClient.patch(`/units/${id}`, entityToSave);
      }

      toastManager.add(
        `Unidad ${saveResponse.data.data.id} guardada con éxito`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => navigate('/units'),
      );

      return saveResponse.data.data;
    } catch (error) {
      handleErrorMessage('Error in save Unit', error);
    }
  }

  return (
    <div>
      <h1 className="page-title">{isAdding ? 'Nueva Unidad' : `Unidad #${id}`}</h1>

      <EntityEditForm
        onRetrieveEntity={onRetrieveEntity}
        onSaveEntity={onSaveEntity}
        addMode={isAdding}>
        <Row>
          <Col>
            <FormInputField id="name" label="Nombre" as="input" defaultValue={name} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInputField id="erpCode" label="Código interno" as="input" defaultValue={erpCode} />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormCheckField id="enabled" label="Activo" defaultChecked={enabled} />
          </Col>
        </Row>
      </EntityEditForm>
    </div>
  );
}

export default withToastManager(UnitEdit);
