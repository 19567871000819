import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';

/** @deprecated Conver the component to functional and use the `useDocumentTitle` hook instead */
export function withDocumentTitle(Component: React.ComponentType<any>, title?: string) {
  function ComponentWithDocumentTitle(props: any) {
    useDocumentTitle(title);

    return <Component {...props} />;
  }

  return ComponentWithDocumentTitle;
}
