// src/hooks/useTheme.ts
import { useState, useEffect } from 'react';
import defaultTheme from '../themes/default';
import { Theme } from '../types/theme';

const useTheme = () => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  useEffect(() => {
    // try to load the custom theme, if it exists
    fetch(`themes/custom.json`)
      .then((response) => {
        response.json().then((customTheme) => {
          const activeTheme: Theme = { ...defaultTheme, ...customTheme };
          setTheme(activeTheme);
        });
      })
      .catch(() => {
        console.debug('Error loading the custom theme.');
      });
  }, []);

  return theme;
};

export default useTheme;
