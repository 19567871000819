import React, { useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { WithRouterProps } from '../withRouter';
import useDocumentTitle from '../../hooks/useDocumentTitle';

function ProductCategory3List({ toastManager }: WithRouterProps) {
  useDocumentTitle('Categorias 3');

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [productCategories3, setProductCategories3] = useState([]);

  useEffect(() => {
    loadProductCategories3();
  }, []);

  function loadProductCategories3() {
    APIClient.get('/product-categories-3')
      .then((res) => {
        setIsDataLoading(false);
        setProductCategories3(res.data.data);
      })
      .catch((err) => {
        console.error('Error in load ProductCategories3', err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        setIsDataLoading(false);
      });
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'description',
      text: 'Descripción',
      sort: true,
    },
    // {
    //   dataField: 'actions',
    //   isDummyField: true,
    //   text: '',
    //   formatter: (cellContent, row) => (
    //     <ButtonToolbar>
    //       <ButtonGroup>
    //         <LinkContainer to={`/product-categories-3/${row.id}`}>
    //           <Button size="sm" variant="outline-primary" title="Editar">
    //             <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
    //           </Button>
    //         </LinkContainer>
    //       </ButtonGroup>
    //     </ButtonToolbar>
    //   ),
    // },
  ];

  const exportColumns = [
    {
      fieldName: 'id',
      title: 'ID',
    },
    {
      fieldName: 'description',
      title: 'Descripción',
    },
  ];

  return (
    <div>
      <h1 className="page-title">Categoría 3</h1>

      <DataTable
        columns={columns}
        data={productCategories3}
        isDataLoading={isDataLoading}
        keyField="id"
        //addButton="/product-categories-3/new"
        addButton={null}
        exportConfig={{
          exportURL: '/product-categories-3/export.xlsx',
          columns: exportColumns,
        }}
      />
    </div>
  );
}

export default withToastManager(ProductCategory3List);
