import { useEffect } from 'react';
import useTheme from './useTheme';

export default function useDocumentTitle(title?: string) {
  const theme = useTheme();

  useEffect(() => {
    const appTitle = theme.appTitle || 'OrderToB - Central de Pedidos';
    if (title) {
      document.title = `${title} - ${appTitle}`;
    } else {
      document.title = appTitle;
    }
  }, [title, theme.appTitle]);

  return null;
}
