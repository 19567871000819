import { ConversationMessageContentType, SenderType, SourceChannel } from '../constants';

export type OrderStatus = {
  codigo: string;
  descripcion: string;
  descripcionExtendidad: string;
};

export type Order = {
  id: number;
  estadoOrderCodigo: string;
  fechaAlta: Date;
  fechaModificacion: Date;
  fechaBaja: Date;
  fechaVencimiento: Date;
  fechaVencimientoFija: Date;
  fechaEntrega: Date;
  clienteId: number;
  vendedorId: number;
  usuarioPerfilId: number;
  listaPrecioId: number;
  condicion_pago_id: number;
  condicionPagoDescripcion: string;
  porcentajeBonificacion: number;
  observaciones: string;
  usuarioAltaId: number;
  usuarioModificacionId: number;
  usuarioBajaId: number;
  marcaCodigo: string;
  medio_pago_id: number;
  puntoEntregaId: number;
  codigo: string;
  provincia_codigo: string;
  localidad: string;
  calle: string;
  numero: string;
  piso: string;
  depto: string;
  codigoPostal: string;
  telefono: string;
  modo_entrega: string;
  precioEntrega: number;
  horaEntregaDesde: string;
  horaEntregaHasta: string;
  fechaConfirmacion: Date;
  transferencistaName: string;
  formaPagoId: number;
  facturaTipo: string;
  purchaseOrder: string;
  aiExecutionId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  sourceChannel: string;

  //relations
  items?: OrderItem[];
  Cliente?: Cliente;
};

export type Cliente = {
  id: number;
  erpCode: string;
  razonSocial: string;
  nombreFantasia: string;
  documentoNumero: string;
  provinciaCodigo: string;
  localidad: string;
  calle: string;
  numero: string;
  piso?: string | null;
  depto?: string | null;
  codigoPostal: string;
  latitud?: number | null;
  longitud?: number | null;
  telefono?: string | null;
  celular?: string | null;
  fax?: string | null;
  email: string;
  eliminadoFlag: boolean;
  tipoId: number;
  vendedorId: number;
  listaPrecioId: number;
  puntoEntregaId: number;
  diasEntrega: number;
  entregaHorario: string;
  contacto?: string | null;
  condicionPagoId: number;
  contactoComercial?: string | null;
  telefonoComercial?: string | null;
  contactoCobranzas?: string | null;
  telefonoCobranzas?: string | null;
  zonaId: number;
  emailComercial?: string | null;
  emailCobranzas?: string | null;
  estadoId: number;
  excludeFromAverageDailySale: boolean;
};

export type Expreso = {
  descripcion: string;
};

export type DeliveryPoint = {
  id: number;
  descripcion: string;
  provinciaCodigo: string;
  localidad: string;
  calle: string;
  numero: string;
  piso: string;
  depto: string;
  codigoPostal: string;
  telefono: string;
  email: string;
  longitud: string;
  latitud: string;
  expresoId: number;
  expreso?: Expreso;
  clienteId: number;
  deliveryTime?: string;
  deliveryDays?: string;
  isEliminado: boolean;

  provincia?: Provincia;
};

export type Product = {
  id: number;
  descripcion: string;
  marcaCodigo: string;
  erpCodigo: string;
  category1Code: string;
  category2Id: number;
  talleId: number;
  colorEstandarId: number;
  category3Id: number;
  typeId: number;
  talleDistribucionPedido1: number;
  talleDistribucionPedido2: number;
  talleDistribucionPedido3: number;
  talleDistribucionPedido4: number;
  talleDistribucionPedido5: number;
  talleDistribucionPedido6: number;
  talleDistribucionPedido7: number;
  talleDistribucionPedido8: number;
  observacion1: string;
  observacion2: string;
  observacion3: string;
  promoFlag: boolean;
  agotadoFlag: boolean;
  novedadFlag: boolean;
  favoritoFlag: boolean;
  fechaAlta: Date;
  fechaModificacion: Date;
  fechaBaja: Date;
  usuarioAltaId: number;
  usuarioModificacionId: number;
  usuarioBajaId: number;
  eliminadoFlag: boolean;
  codigoEan13: string;
  proveedorId: number;
  isCombo: boolean;
  isVentaBulto: boolean;
  isInternal: boolean;
  posicion: number;
};

export type OrderItem = {
  id: number | null;
  orderId: number;
  productId: number | null;
  posicion: number;
  cantidad: number | null;
  precio: number | null;
  listaPrecioId: number | null;
  entregado: number;
  modificadoresPrecio: string;
  aiOrderItemIndex: number | null;
  keyData?: string;
  // relations
  Product?: Product;
  order?: Order;
  // extra
  aiData?: AiOrderItem;
  originalPrice?: number | null;
};

export type PriceListResume = {
  id: number;
  tipo: string;
  codigo: string;
  descripcion: string;
  eliminadoFlag: boolean;
};

export interface PriceList extends PriceListResume {
  detalles: PriceListDetail[];
}

export type PriceListDetail = {
  id: number;
  listaPrecioId: number;
  productId: number;
  talleNombre: string;
  precio: string;
  Product: Array<Product>;
};

export type AIExecution = {
  id: number;
  status: string;
  startedAt: Date;
  finishedAt: Date;
  fileId: number | null;
  medium: string;
  request: string;
  response: string;
  resultOrder: string;
  oaCompletionId: string;
  oaUsedTokensInput: number;
  oaUsedTokensOutput: number;
};

enum AIExecutionStatus {
  processing = 'processing',
  success = 'success',
  error = 'error',
}

enum OrderItemMatchByFields {
  none = 'none',
  code = 'code',
  eanCode = 'ean-code',
  description = 'description',
}

export enum OrderClientMatchByFields {
  missing = 'missing',
  none = 'none',
  email = 'email',
  phone = 'phone',
  name = 'name',
  id = 'id',
  deliveryPoint = 'deliveryPoint',
}

enum OrderDeliveryPointMatchByFields {
  missing = 'missing',
  none = 'none',
  name = 'name',
  address = 'address',
}

export interface InterpretOrderResult {
  client: {
    id?: number;
    ids?: number[];
    contactId?: number;
    hint?: string;
    matchBy: OrderClientMatchByFields;
    matchPrecision: number;
  };
  deliveryDate?: string;
  deliveryPoint: {
    id?: number;
    hint?: string;
    matchBy: OrderDeliveryPointMatchByFields;
    matchPrecision: number;
  };
  items: AiOrderItem[];
  notes?: string;
}

export interface AiOrderItem {
  matchBy: string;
  matchPrecision: number;
  product: string;
  productCode: string;
  productId?: number;
  quantity: number;
  unit: string;
}

export interface PaymentMethod {
  id: number;
  description: string;
}

export interface Agent {
  id: number;
  nombre: string;
  email: string;
}

export type Provincia = {
  codigo: string;
  descripcion: string;
  eliminadoFlag: boolean;
};

export interface Contact {
  id?: number;
  clientId: number;
  deliveryPointId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface Conversation {
  id: number;
  sourceChannel: SourceChannel;
  clientId: number | null;
  externalCode: string;
  aiExecutionId: number | null;
  aiReference: number | null;
  createdAt: string;
  updatedAt: string;
  finishedAt: string | null;
  client: Cliente;
  messages: ConversationMessage[];
}

export interface ConversationMessage {
  id: number;
  conversationId: number;
  timestamp: string;
  content: string;
  contentParsed: string | null;
  contentType: ConversationMessageContentType;
  externalCode: string;
  senderIdentifier: string | null;
  senderType: SenderType;
  createdAt: string;
  updatedAt: string;
  files?: any;
}

export interface Unit {
  id: number | null;
  name: string;
  erpCode: string;
  enabled: boolean;
}

export interface ClientAIParams {
  clientId: number;
  productMatchInstructions: string;
  updatedAt: Date;
}

export interface Company {
  id: number;
  companyName: string;
  countryCode: string;
  taxCode?: string | null;
  tradeName?: string | null;
  sector?: string | null;
  briefDescription?: string | null;
  contactInfo?: string | null;
  logoFileId?: string | null;
  enabled: boolean;
  updatedAt?: Date | null;
  greeting?: string | null;
}

export interface Role {
  id: number;
  codigo: string;
  descripcion: string;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  username: string;
  algorithm: string;
  salt: string;
  password: string;
  isActive: boolean;
  isSuperAdmin: boolean;
  lastLogin: Date | null;
  type: string;
  passwordRecoveryToken: string | null;
  passwordRecoveryDate: Date | null;
}
