import React from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
import { withDocumentTitle } from '../withDocumentTitle';

class MarcaList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  /**
   * Initializes the MarcaList component state and binds methods.
   *
   * @param {Object} props - The component properties.
   * @param {Object} props.toastManager - The toast manager for displaying notifications.
   */
  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      isDataLoading: true,
    };

    this.loadMarcas = this.loadMarcas.bind(this);
  }

  componentDidMount() {
    this.loadMarcas();
  }

  loadMarcas() {
    const { toastManager } = this.props;

    APIClient.get('/marcas?sortField=codigo&sortDir=asc')
      .then((res) => {
        this.setState({
          entities: res.data.data,
          isDataLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { entities, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'codigo',
        text: 'Código',
        sort: true,
      },
      {
        classes: 'w-75',
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        classes: 'text-center',
        dataField: 'eliminadoFlag',
        text: 'Estado',
        headerClasses: 'text-center',
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          cellContent =
            typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
          const UISettings = {
            text: { true: 'Inactivo', false: 'Activo' },
            color: { true: 'danger', false: 'success' },
          };
          return UIUtils.getStatusBadge(cellContent, UISettings);
        },
      },
      // { TEMPORARY READ-ONLY
      //  dataField: 'actions',
      //  isDummyField: true,
      //  text: '',
      //  formatter: (cellContent, row) => (
      //    <ButtonToolbar>
      //      <ButtonGroup>
      //        <LinkContainer to={`/marcas/${row.codigo}`}>
      //          <Button size="sm" variant="outline-primary" title="Editar">
      //            <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
      //          </Button>
      //        </LinkContainer>
      //      </ButtonGroup>
      //    </ButtonToolbar>
      //  ),
      // },
    ];

    /* EXPORT DATA TO EXCEL */
    const exportColums = [
      {
        fieldName: 'codigo',
        title: 'Código',
      },
      {
        fieldName: 'descripcion',
        title: 'Descripción',
      },
      {
        fieldName: 'eliminadoFlag',
        title: 'Inactivo',
        parseBoolean: true,
      },
    ];

    return (
      <div>
        <h1 className="page-title">Marcas</h1>

        <DataTable
          columns={columns}
          cellEdit={false}
          data={entities}
          isDataLoading={isDataLoading}
          keyField="codigo"
          // addButton="/marcas/nuevo"
          addButton={null}
          defaultSorted={[{ dataField: 'codigo', order: 'asc' }]}
          exportConfig={{
            exportURL: '/marcas/export.xlsx',
            columns: exportColums,
          }}
        />
      </div>
    );
  }
}

export default withToastManager(withDocumentTitle(MarcaList, 'Marcas'));
