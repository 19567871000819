import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Conversation } from '../../types/model';
import APIClient from '../../services/APIClient';
import { withRouter } from '../withRouter';
import { withToastManager } from 'react-toast-notifications';
import ConversationListItem from '../../components/ConversationListItem';
import ConversationManager from '../../components/ConversationManager';
import FormSelectCustom from '../../components/FormSelectCustom';
import { FormInputField, FormSelectField, Pagination } from '../../components';
import './styles.css';
import config from '../../config';
import useDocumentTitle from '../../hooks/useDocumentTitle';

function ConversationList({ navigate, params, toastManager }) {
  useDocumentTitle('Conversaciones');

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [totalConversations, setTotalConversations] = useState(0);
  const [pageSelected, setPageSelected] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const conversationIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const CONVERSATIONS_PER_PAGE = 10;

  useEffect(() => {
    if (isLoading) return;

    setIsLoading(true);
    loadConversations().finally(() => setIsLoading(false));

    return () => stopAutoReload();
  }, [pageSelected]);

  // Loads conversations and sets the state, with optional reload behavior
  function loadConversations() {
    const offset = CONVERSATIONS_PER_PAGE * (pageSelected - 1);

    return APIClient.get(
      `/conversations/details?limit=${CONVERSATIONS_PER_PAGE}&offset=${offset}&sortField=createdAt&sortDir=DESC`,
    )
      .then((res) => {
        const { data, meta } = res.data;
        setConversations(data);
        setTotalConversations(meta.total);

        // Start auto-reload if not already started
        if (!conversationIntervalRef.current) {
          conversationIntervalRef.current = setInterval(
            loadConversations,
            parseInt(config.conversation.listUpdateInterval!),
          );
        }
      })
      .catch((err) => {
        toastManager.add('Se detuvo la actualización automática del listado de conversaciones.', {
          appearance: 'error',
        });
        console.error('Error cargando listado de conversaciones. Se detuvo el auto-reload.', err);
        stopAutoReload();
      });
  }

  function stopAutoReload() {
    if (conversationIntervalRef.current) {
      clearInterval(conversationIntervalRef.current);
      conversationIntervalRef.current = null;
    }
  }

  // if not numeric id, then redirect to conversations' root
  if (params?.id && !/^\d+$/.test(params?.id!)) {
    navigate('/conversations', { replace: true });
    return null;
  }

  function handleCollapseFiltersButton(e) {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  }

  function handleOnChangePaginationButton(page: number) {
    setPageSelected(page);
  }

  return (
    <>
      {/* FILTERS - disabled by now */}
      <Card className="my-3">
        <Card.Header className="d-flex justify-content-between">
          <Button
            onClick={handleCollapseFiltersButton}
            className="mt-2 mb-2 bg-transparent btn btn-link">
            Filtros de búsqueda{' '}
            {
              <FontAwesomeIcon
                icon={isCollapsed ? faCaretRight : faCaretDown}
                className="ml-1"
                fixedWidth
              />
            }
          </Button>
        </Card.Header>
        {
          /* {!isCollapsed */ false && (
            <>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <FormSelectCustom
                      id="client"
                      label="Clientes"
                      /* onLoadOptions={selectCustomLoadClientOptionsHandler}
                  onChange={handleChangeValue}
                  selectedOption={filters.client === undefined ? null : undefined}
                  disabled={isFilterLoadingData} */
                    />
                  </Col>
                  <Col md={6}></Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <FormInputField
                      id="purchaseOrder"
                      label="Orden de compra"
                      /* value={filters.purchaseOrder ?? ''}
                  disabled={isFilterLoadingData}
                  onChange={handleChangeValue} */
                    />
                  </Col>
                  <Col md={6}>
                    <FormInputField
                      id="freeText"
                      label="Texto libre"
                      /* value={filters.freeText ?? ''}
                  disabled={isFilterLoadingData}
                  onChange={handleChangeValue} */
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <FormSelectField
                      id="sourceChannel"
                      label="Canal de origen"
                      choices={[]}
                      /* choices={sourceChannelsChoices}
                  value={filters.sourceChannel ?? ''}
                  disabled={isFilterLoadingData}
                  choiceIdField="id"
                  choiceLabelField="label"
                  onChange={handleChangeValue} */
                    />
                  </Col>
                  <Col md={6}>
                    <FormSelectCustom
                      id="user"
                      label="Usuario"
                      /* onLoadOptions={selectCustomLoadUserOptionsHandler}
                  onChange={handleChangeValue}
                  selectedOption={filters.user === undefined ? null : undefined}
                  disabled={isFilterLoadingData} */
                      clearable={true}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <div className="d-flex flex-row justify-content-end">
                  <Button
                    disabled={false} // isFilterLoadingData
                    className="d-flex py-2 mx-1 my-3"
                    variant="primary"
                    /* onClick={handleSubmitClick} */
                  >
                    {!true ? ( // isDataLoading
                      <p className="m-0">Buscar</p>
                    ) : (
                      <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    className="d-flex py-2 mx-1 my-3"
                    /* onClick={handleCleanClick} */
                  >
                    Limpiar Filtros
                  </Button>
                </div>
              </Card.Footer>
            </>
          )
        }
      </Card>

      <div className="d-flex flex-column">
        {/* CONVERSATIONS */}
        <div className="d-flex flex-row">
          <div
            className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white"
            style={{ width: '25vw' }}>
            <span className="fs-5 fw-semibold">Conversaciones</span>
            <div className="list-group list-group-flush border-bottom scrollarea">
              {conversations.length > 0 ? (
                conversations.map((conversation) => (
                  <ConversationListItem key={conversation.id} conversation={conversation} />
                ))
              ) : (
                <div>No hay conversaciones</div>
              )}
            </div>
            {totalConversations > 0 && (
              <Pagination
                pageSelected={pageSelected}
                totalPages={Math.ceil(totalConversations / CONVERSATIONS_PER_PAGE)}
                disabled={isLoading}
                onChange={handleOnChangePaginationButton}
              />
            )}
          </div>

          {/* MESSAGES */}
          {params?.id ? (
            <ConversationManager />
          ) : (
            <div className="m-auto w-fit fs-3">Seleccione una conversación.</div>
          )}
        </div>
      </div>
    </>
  );
}

export default withToastManager(withRouter(ConversationList));
