import React from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
import { withDocumentTitle } from '../withDocumentTitle';

class VendedorList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDataLoading: true,
      vendedores: [],
    };

    this.loadVendedores = this.loadVendedores.bind(this);
  }

  componentDidMount() {
    this.loadVendedores();
  }

  loadVendedores() {
    const { toastManager } = this.props;

    APIClient.get('/vendedores')
      .then((res) => {
        this.setState({
          isDataLoading: false,
          vendedores: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { isDataLoading, vendedores } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'nombre',
        text: 'Nombre',
        sort: true,
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
      },
      {
        dataField: 'phoneNumber',
        text: 'Teléfono',
        sort: true,
      },
      {
        dataField: 'eliminadoFlag',
        text: 'Estado',
        sort: true,
        formatter: (cellContent) => {
          if (cellContent === null) {
            return '';
          }
          const newCellContent =
            typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
          const UISettings = {
            text: { true: 'Inactivo', false: 'Activo' },
            color: { true: 'danger', false: 'success' },
          };
          return UIUtils.getStatusBadge(newCellContent, UISettings);
        },
      },
      // {
      //   dataField: 'actions',
      //   isDummyField: true,
      //   text: '',
      //   formatter: (cellContent, row) => (
      //     <ButtonToolbar>
      //       <ButtonGroup>
      //         <LinkContainer to={`/vendedores/${row.id}`}>
      //           <Button size="sm" variant="outline-primary" title="Editar">
      //             <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
      //           </Button>
      //         </LinkContainer>
      //       </ButtonGroup>
      //     </ButtonToolbar>
      //   ),
      // },
    ];

    /* EXPORT DATA TO EXCEL */
    const exportColumns = [
      {
        fieldName: 'id',
        title: 'ID',
      },
      {
        fieldName: 'nombre',
        title: 'Nombre',
      },
      {
        fieldName: 'email',
        title: 'Email',
      },
      {
        fieldName: 'eliminadoFlag',
        title: 'Inactivo',
        parseBoolean: true,
      },
    ];

    return (
      <div>
        <h1 className="page-title">Vendedores</h1>
        <DataTable
          columns={columns}
          data={vendedores}
          isDataLoading={isDataLoading}
          keyField="id"
          exportConfig={{
            exportURL: '/vendedores/export.xlsx',
            columns: exportColumns,
          }}
        />
      </div>
    );
  }
}

export default withToastManager(withDocumentTitle(VendedorList, 'Vendedores'));
